import { Grid } from "antd"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import React from "react"

import style from "./MainTop.module.scss"
import { useBanners } from "../../../app/services/queries"
import ViewAdv from "../../../../common/view-adv/ViewAdv"
import Image from "next/image"
import MainFilter from "../main-filter/MainFilter"
import { useCheckAuth } from "pages/main-layout/services/queries"
import Banner from "../main-filter/banner/Banner"

const MainTop: React.FC = () => {
  const t = useTranslations("body.mainTop")
  const router = useRouter()
  const { data: banners, isLoading } = useBanners("main", router.locale)
  const { md } = Grid.useBreakpoint()
  useCheckAuth()

  return (
    <section
      className={`${style.main_top} watch`}
      style={{
        backgroundImage:
          isLoading || banners?.top?.images?.web?.url ? "none" : "",
      }}
      id="main_top"
    >
      <div
        className="wrapper d_f fd_c ai_c p_r"
        style={{
          backgroundImage:
            isLoading || banners?.top?.images?.web?.url ? "none" : "",
        }}
      >
        {md && banners?.top?.images?.web?.url ? (
          <ViewAdv uuid={banners?.top?.uuid}>
            <div className={style.reklama}>
              <div className={style.reklama_bg}>
                <div className={style.reklama_bg_main}></div>
                <Image src={banners?.top?.images?.web?.url || ""} fill alt="ADS logo" />
              </div>
            </div>
          </ViewAdv>
        ) : (
          <></>
        )}

        <div
          className={`${style.main_top_top} w-full`}
          style={{
            backgroundImage:
              isLoading || banners?.top?.images?.web?.url ? "none" : "",
          }}
        >
          {!md &&
          (banners?.top?.images?.web?.url ||
            banners?.top?.images?.mobile?.url) ? (
            <ViewAdv uuid={banners?.top?.uuid}>
              <div className={style.reklama}>
                <div className="w-full h-full relative">
                  <div
                    className={`absolute top-0 left-0 w-full h-full ${style.reklama_back}`}
                  />
                  <img
                    src={
                      banners?.top?.images?.mobile?.url ||
                      banners?.top?.images?.web?.url ||
                      ""
                    }
                    alt="ADS logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute left-0 bottom-5 sm:w-max w-full">
                  <Banner banner={banners?.top} />
                </div>
              </div>
            </ViewAdv>
          ) : (
            <></>
          )}
          <div className="flex flex-col justify-center w-full items-center pt-[60px]">
            <h1 className="!md:text-center">
              {t("Найди дом своей мечты прямо сейчас")}
            </h1>

            <MainFilter banner={banners?.top} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainTop
