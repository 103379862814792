import { Spin } from "antd"
import ArrowUpIcon from "assets/icons/ArrowUpIcon"
import { useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetHotFilters } from "pages/main/services/queries"
import React from "react"
import { RU, USD } from "utils/constants/locales"
import { rootPaths } from "utils/constants/rootPaths"
import { calcUsdValue } from "utils/helpers/calcUsdValue"
import { filterPriceSeparator } from "utils/helpers/filterPriceSeparator"
import { layoutLang } from "utils/language/layout"
import HotOffersLocation from "./HotOffersLocation"
import EmptyOfferIcon from "assets/icons/EmptyOfferIcon"

export const sub_titles = {
  price: { uz: "Eng arzon m² xonadon", ru: "Самые низкие цены за м²" },
  total_price: { uz: "Eng arzon xonadon", ru: "Самая дешевая квартира" },
  prepayment: {
    uz: "Eng past boshlang’ich to‘lov",
    ru: "Самый низкий первый взнос",
  },
  delay: { uz: "Eng uzoq muddatli to‘lov", ru: "Самый долгий период оплаты" },
  discount_percent: { uz: "Eng yuqori chegirmali", ru: "Самая высокая скидка" },
  monthly_price: {
    uz: "Eng past oylik to'lov",
    ru: "Самый низкий ежемес. платёж",
  },
}

const HotOffers = () => {
  const { locale, push } = useRouter()
  const { currency, usdValue } = useAppSelector((state) => state.localeReducer)
  const { location } = useAppSelector((state) => state.favouritesReducer)

  const regionId = location?.regionValue?.city
    ? location?.regionValue?.city?.id
    : location?.regionValue?.district?.city?.id
  const districtId =
    location?.regionValue?.district && location?.regionValue?.district?.id

  const { data, isLoading } = useGetHotFilters(regionId, districtId)
  const newData = data?.map((item) => ({
    ...item,
    sub_title:
      locale === "ru"
        ? sub_titles[item?.sort_key as keyof typeof sub_titles]?.ru
        : sub_titles[item?.sort_key as keyof typeof sub_titles]?.uz,
  }))

  const handleSort = (sort_key: string, sort_value: string) => {
    if (regionId && districtId) {
      push({
        query: {
          sort_value: sort_value,
          sort_key: sort_key,
          city_id: regionId,
          district_id: districtId,
        },
        pathname: `/${
          location?.regionValue?.city?.url ||
          location?.regionValue?.district?.city?.url
        }${rootPaths.APARTMENTS}`,
      })
    } else if (regionId) {
      push({
        query: {
          sort_value: sort_value,
          sort_key: sort_key,
          city_id: regionId,
        },
        pathname: `/${
          location?.regionValue?.city?.url ||
          location?.regionValue?.district?.city?.url
        }${rootPaths.APARTMENTS}`,
      })
    } else if (districtId) {
      push({
        query: {
          sort_value: sort_value,
          sort_key: sort_key,
          district_id: districtId,
        },
        pathname: `/${location?.regionValue?.district?.city?.url}${rootPaths.APARTMENTS}`,
      })
    } else {
      push({
        query: {
          sort_value: sort_value,
          sort_key: sort_key,
        },
        pathname: `/gorod-tashkent${rootPaths.APARTMENTS}`,
      })
    }
  }

  return (
    <div
      className={`bg-white rounded-xl mt-[70px] px-2 py-3 left-6 flex flex-col gap-4 sticky top-[100px] h-max min-w-[296px] max-w-[296px]`}
    >
      <div className="flex flex-col gap-1">
        <span className="text-[#344054] text-lg font-semibold leading-[22px]">
          {layoutLang[String(locale)]["qaynoq_takliflar"]}
        </span>
        <HotOffersLocation />
      </div>
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-3">
          {newData && newData?.length > 0 ? (
            newData?.map((item, index) => (
              <div
                key={index}
                className="flex flex-col gap-[1px] p-2 pl-3 bg-[#F9FAFB] border border-solid border-[#F2F4F7] rounded-xl cursor-pointer"
                onClick={() => handleSort(item?.sort_key, item?.sort_value)}
              >
                <div className="flex items-center justify-between">
                  <span className="text-[#1D2939] text-[22px] leading-[27px] font-medium">
                    {item?.sort_key === "delay" ||
                    item?.sort_key === "discount_percent"
                      ? item?.sort_key === "delay"
                        ? `${item?.value} ${layoutLang[String(locale)]["oy"]}`
                        : `${item?.value} %`
                      : `${
                          currency === USD
                            ? `${Math.ceil(
                                calcUsdValue(item?.value, usdValue, currency)
                              )?.toLocaleString(RU)} $`
                            : `${filterPriceSeparator(item?.value, locale)} ${
                                layoutLang[String(locale)]["sum"]
                              }`
                        }`}
                  </span>
                  {item?.percent && (
                    <div className="flex items-center gap-0.5">
                      {item?.percent < 0 ? (
                        <ArrowUpIcon />
                      ) : (
                        <div className="rotate-180">
                          <ArrowUpIcon stroke="#F04438" />
                        </div>
                      )}
                      <span
                        className={`text-xs font-medium ${
                          item?.percent > 0
                            ? "text-[#F04438]"
                            : "text-[#12B76A]"
                        }`}
                      >{`${item?.percent
                        ?.toString()
                        ?.replace("-", "")} %`}</span>
                    </div>
                  )}
                </div>
                <span className="text-[#00A389] text-[13px] font-medium">
                  {item?.sub_title}
                </span>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center gap-3.5 mt-6">
              <EmptyOfferIcon />
              <span className="text-[#344054] text-base font-semibold">
                {layoutLang[String(locale)]["malumot topilmadi"]}
              </span>
            </div>
          )}
        </div>
      </Spin>
    </div>
  )
}

export default HotOffers
