import { useTranslations } from "next-intl"
import React from "react"

import BuilderCard from "../../../../common/builderCard/BuilderCard"
import { viewTypes } from "../../../../utils/constants/viewTypes"
import { useGetTopBuilders } from "../../services/queries"

import styles from "./MainTopBuilders.module.scss"
import BuildersQuiz from "../builders-quiz/BuildersQuiz"

const MainTopBuilders: React.FC = () => {
  const t = useTranslations("body.topBuilders")
  const { data } = useGetTopBuilders()

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.container_header}>
          <h2>{t("Топовые застройщики")}</h2>
        </div>
        <div className={styles.container_body}>
          {data?.map((item, index) => (
            <BuilderCard {...item} key={index} viewType={viewTypes.GRID} />
          ))}
        </div>
        <BuildersQuiz />
      </div>
    </section>
  )
}

export default MainTopBuilders
